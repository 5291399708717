import {injectable} from '@123/druid/dist/Framework/Decorators/Inject';
import Rectangle from '@123/druid/dist/Utility/Rectangle';
import type IScrollOptions from './IScrollOptions';

/**
 * Can scroll containers such, that a specified child element must align with the bounds of the container.
 */
@injectable({key: 'WindowScroller', args: [window]})
export default class Scrollable {
    private bounds: Rectangle = new Rectangle(0, 0, 0, 0);

    constructor(private scrollTarget: Window | HTMLElement) {
    }

    public setBounds(bounds: Rectangle): void {
        this.bounds = bounds;
    }

    public scrollElement(childElement: HTMLElement, scrollOptions?: IScrollOptions): void {
        const currentValues = {
            y: this.scrollTarget instanceof Window ? this.scrollTarget.scrollY : this.scrollTarget.scrollTop,
            x: this.scrollTarget instanceof Window ? this.scrollTarget.scrollX : this.scrollTarget.scrollLeft
        };

        // The x and y here are the target values of our scroll. Initially, they are set to the current scroll position.
        const endProps      = {y: currentValues.y, x: currentValues.x};
        const elementOffset = childElement.getPosition();

        // @see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
        // @TODO: 'nearest' is currently not supported for 'inline' and 'block' properties.
        if (scrollOptions?.inline !== undefined) {
            endProps.x = elementOffset.x - this.bounds.left;
            switch (scrollOptions.inline) {
                case 'end':
                    endProps.x += childElement.getWidth('outer');
                    break;
                case 'center':
                    endProps.x += childElement.getWidth('outer') / 2;
                    break;
                default:
                    // nearest is unsupported. 'start' is the actual default!
                    break;
            }
        }

        if (scrollOptions?.block !== undefined) {
            endProps.y = elementOffset.y - this.bounds.top;
            switch (scrollOptions.block) {
                case 'end':
                    endProps.y += childElement.getHeight('outer');
                    break;
                case 'center':
                    endProps.y += childElement.getHeight('outer') / 2;
                    break;
                default:
                    // nearest is unsupported. 'start' is the actual default!
                    break;
            }
        }

        this.scrollTarget.scrollTo({left: endProps.x, top: endProps.y, behavior: scrollOptions?.behavior ?? 'smooth'});
    }
}
