import tag from '@123/druid/dist/Framework/Decorators/Tag';
import AttributeHandler from '@Component/Slider/AttributeHandler';
import DragHandler from '@Component/Slider/EventHandler/DragHandler';
import TrackHandler from '@Component/Slider/EventHandler/TrackHandler';
import type SliderContext from '@Component/Slider/Types/SliderContext';
import SliderLayout from '@Component/Slider/SliderLayout';
import SliderUI from '@Component/Slider/SliderUI';
import ResizeHandler from '@Component/Slider/EventHandler/ResizeHandler';
import TrackController from '@Component/Slider/TrackController';
import type {SliderAlign, SliderOrientation} from '@Component/Slider/Types/SliderTypes';
import type {SliderEvents, SlideDetails} from '@Component/Slider/Types/SliderEvents';
import * as defaults from '@Component/Slider/FixedSliderDefaultValues';
import raise from '@123/druid/dist/Utility/Raise';
import type {SliderProperties} from '@Component/Slider/Types/SliderOptions';
import type {SliderPageInfo} from '@Component/Slider/Types/SliderInfo';

@tag('dr-fixed-slider')
export default class FixedSlider extends HTMLElement implements SliderEvents {
    private context?: SliderContext;

    get slidesVisible(): number {
        return this.attributeMap().getFloat('slides-visible', defaults.slidesVisible);
    }

    set slidesVisible(amount: number) {
        this.attributeMap().setFloat('slides-visible', amount);
    }

    get gap(): number {
        return this.attributeMap().getFloat('gap', defaults.gap);
    }

    set gap(amount: number) {
        this.attributeMap().setFloat('gap', amount);
    }

    get orientation(): string {
        return this.attributeMap().getString('orientation', defaults.orientation);
    }

    set orientation(orientation: SliderOrientation) {
        this.attributeMap().setString('orientation', orientation);
    }

    get alignTo(): string {
        return this.attributeMap().getString('align-to', defaults.align);
    }

    set alignTo(align: SliderAlign) {
        this.attributeMap().setString('align-to', align);
    }

    get activePage(): number {
        return this.attributeMap().getInt('active-page', defaults.activePage);
    }

    set activePage(index: number) {
        this.attributeMap().setInt('active-page', index);
    }

    get slidesPerPage(): number {
        return this.attributeMap().getInt('slides-per-page', defaults.slidesPerPage);
    }

    set slidesPerPage(amount: number) {
        this.attributeMap().setInt('slides-per-page', amount);
    }

    get transitionSpeed(): number {
        return this.attributeMap().getInt('transition-speed', defaults.transitionSpeed);
    }

    set transitionSpeed(amount: number) {
        this.attributeMap().setInt('transition-speed', amount);
    }

    get pageCount(): number {
        return this.getContext().layout.getPageCount();
    }

    get slideCount(): number {
        return this.getContext().ui.slides.length;
    }

    public getSlidesForPage(pageIndex: number): HTMLElement[] {
        return this.getContext().layout.getSlidesForPage(pageIndex);
    }

    public getVisibleSlides(): HTMLElement[] {
        return this.getContext().layout.getVisibleSlides();
    }

    public getPageInfo(): SliderPageInfo {
        return {
            activePage: this.activePage,
            pageCount: this.pageCount,
            onFirstPage: this.activePage === 0,
            onLastPage: (this.activePage + 1) === this.pageCount
        };
    }

    public addSlide(slideElement: HTMLElement, atIndex?: number): void {
        const pos = this.getContext().layout.addSlide(slideElement, atIndex);
        if (pos === -1) {
            // slide was not added
            throw new Error('Slide already exists');
        }

        this.dispatchEvent(new CustomEvent<SlideDetails>('slideAdded', {
            bubbles: false,
            cancelable: true,
            detail: {index: pos, element: slideElement}
        }));
    }

    public removeSlide(slideElementOrIndex: HTMLElement | number): void {
        const context = this.getContext();
        let slideElement: HTMLElement;

        if (typeof slideElementOrIndex === 'number') {
            slideElement = context.ui.slides[slideElementOrIndex] ?? raise(`Slide with index ${slideElementOrIndex} does not exist.`);
        } else {
            slideElement = slideElementOrIndex;
        }

        context.layout.removeSlide(slideElement);
    }

    public nextPage(): boolean {
        if (this.activePage < this.pageCount - 1) {
            this.activePage++;
            return true;
        }
        return false;
    }

    public prevPage(): boolean {
        if (this.activePage > 0) {
            this.activePage--;
            return true;
        }
        return false;
    }

    public gotoSlide(index: number): void {
        if (index >= 0 && index <= this.pageCount - 1) {
            this.activePage = index;
        }
    }

    public setOptions(options: Partial<SliderProperties>): void {
        if (options.slidesVisible) {
            this.slidesVisible = options.slidesVisible;
        }
        if (options.gap) {
            this.gap = options.gap;
        }
        if (options.orientation) {
            this.orientation = options.orientation;
        }
        if (options.alignTo) {
            this.alignTo = options.alignTo;
        }
        if (options.activePage) {
            this.activePage = options.activePage;
        }
        if (options.slidesPerPage) {
            this.slidesPerPage = options.slidesPerPage;
        }
        if (options.transitionSpeed) {
            this.transitionSpeed = options.transitionSpeed;
        }
    }

    /** element is added to the DOM */
    public connectedCallback(): void {
        this.createContext();
        this.getContext().resizeHandler.attach();
        this.getContext().dragHandler.attach();
        this.getContext().trackHandler.attach();
    }

    /** element is removed from the DOM */
    public disconnectedCallback(): void {
        this.getContext().resizeHandler.detach();
        this.getContext().dragHandler.detach();
        this.getContext().trackHandler.detach();
    }

    static get observedAttributes(): string[] {
        return AttributeHandler.observedAttributes;
    }

    public attributeChangedCallback(name: string, _oldValue: string, newValue: string | null): void {
        if (this.context === undefined) {
            // attributeChangedCallback will be called before connected callback on first render.
            // Therefore, we can not use this.getContext() here. It would always throw an error, because at that moment there will never be a context.
            return;
        }
        new AttributeHandler(this.context.ui, this.context.layout, this.context.trackController).handleAttribute(name, newValue, _oldValue);
    }

    private createContext(): void {
        const ui              = new SliderUI(this);
        const layout          = new SliderLayout(ui);
        const trackController = new TrackController(ui, layout);
        const resizeHandler   = new ResizeHandler(ui, layout, trackController);
        const dragHandler     = new DragHandler(ui, trackController);
        const trackHandler    = new TrackHandler(ui, layout, trackController);

        this.context = {ui, resizeHandler, trackController, layout, dragHandler, trackHandler};
    }

    private getContext(): SliderContext {
        if (this.context === undefined) {
            throw new Error('No slider context found.');
        }

        return this.context;
    }
}
