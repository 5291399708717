import type FilterModel from '@Module/Group/Component/QuickFilter/WebComponent/Model/FilterModel';
import type QuickFilterUI from '@Module/Group/Component/QuickFilter/WebComponent/QuickFilterUI';

export default class ChangeHandler {
    private readonly events = 'input.quickfilter paste.quickfilter';
    private isAttached      = false;

    constructor(private readonly ui: QuickFilterUI, private readonly filter: FilterModel) {
    }

    public attach(): ChangeHandler {
        if (this.isAttached) {
            throw new Error('ChangeHandler was already attached.');
        }

        this.ui.inputElement.addEventListener(this.events, (event) => {
            event.stopImmediatePropagation();
            event.stopPropagation();
            this.filter.setFilterText(this.ui.inputElement.value);
        });

        this.isAttached = true;
        return this;
    }

    public detach(): void {
        if (this.isAttached === false) {
            throw new Error('ChangeHandler was not attached.');
        }

        this.ui.inputElement.removeEventListeners(this.events);
        this.isAttached = false;
    }
}
