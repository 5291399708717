import BrowserStorage from '@123/druid/dist/Services/Storage/BrowserStorage';
import LocalStorageEngine from '@123/druid/dist/Services/Storage/LocalStorageEngine';

export default class QuickFilterStorage extends BrowserStorage {
    // 10 minutes
    private static TTL: number = 1000 * 60 * 10;

    constructor(quickFilterPageId: string) {
        super(new LocalStorageEngine('quickfilter-' + quickFilterPageId, {ttl: QuickFilterStorage.TTL}));
    }
}
