import type FilterModel from '@Module/Group/Component/QuickFilter/WebComponent/Model/FilterModel';
import type QuickFilterUI from '@Module/Group/Component/QuickFilter/WebComponent/QuickFilterUI';

export default class ResetHandler {
    private readonly events = 'click.quickfilter';
    private isAttached      = false;

    constructor(
        private readonly ui: QuickFilterUI,
        private readonly filter: FilterModel
    ) {
    }

    public attach(): ResetHandler {
        if (this.isAttached) {
            throw new Error('ChangeHandler was already attached.');
        }

        this.ui.resetButton.addEventListener(this.events, (event) => {
            event.stopImmediatePropagation();
            event.stopPropagation();
            this.filter.setFilterText('');
            this.ui.element.dispatchEvent(new Event('reset', {bubbles: true, cancelable: true}));
        });

        this.filter.addChangeListener((newValue: string) => {
            if (newValue === '') {
                this.ui.resetButton.classList.add('hidden');
                return;
            }
            this.ui.resetButton.classList.remove('hidden');
        });

        this.isAttached = true;
        return this;
    }

    public detach(): void {
        if (this.isAttached === false) {
            throw new Error('ChangeHandler was not attached.');
        }

        this.ui.resetButton.removeEventListeners(this.events);
        this.isAttached = false;
    }
}
