import type ElementList from '@Component/ElementList/ElementList';
import type FixedSlider from '@Component/Slider/FixedSlider';

export default class SliderUI {
    public readonly track: ElementList;

    constructor(public readonly element: FixedSlider) {
        this.track = this.element.queryRole<ElementList>('track');
    }

    get slides(): HTMLElement[] {
        return this.track.visibleElements;
    }
}
