import Point from '@123/druid/dist/Utility/Point';
import type SliderUI from '@Component/Slider/SliderUI';
import type TrackController from '@Component/Slider/TrackController';

const eventTouchStart = 'touchstart.slider';
const eventTouchMove  = 'touchmove.slider';
const eventTouchEnd   = 'touchend.slider';

export default class DragHandler {
    private dragStartPos: Point | null = null;

    constructor(private readonly ui: SliderUI, private readonly trackController: TrackController) {
    }

    public attach(): void {
        this.addStartListener();
        this.addMoveListener();
        this.addEndListener();
    }

    public detach(): void {
        this.ui.element.removeEventListeners(eventTouchStart);
        document.removeEventListeners([eventTouchEnd, eventTouchMove].join(' '));
    }

    private addStartListener(): void {
        (<EventTarget> this.ui.element).addEventListener(eventTouchStart, (evt: Event): void => {
            const touch = (<TouchEvent>evt).touches[0];
            if (touch === undefined) {
                return;
            }

            const x           = touch.clientX;
            const y           = touch.clientY;
            this.dragStartPos = new Point(x, y);
            this.trackController.startDrag(this.dragStartPos);
        });
    }

    private addMoveListener(): void {
        // note: this is a passive event listener, so we can preventDefault.
        (<EventTarget>document).addEventListener(eventTouchMove, (evt: Event): void => {
            if (this.dragStartPos === null) {
                // we're not dragging, just moving our cursor over the element
                return;
            }
            const touch = (<TouchEvent>evt).touches[0];
            if (touch === undefined) {
                return;
            }

            const x = touch.clientX;
            const y = touch.clientY;

            const movePosition   = new Point(x, y);
            const moveHorizontal = Math.abs(movePosition.x - this.dragStartPos.x) > Math.abs(movePosition.y - this.dragStartPos.y);

            if ((moveHorizontal && this.ui.element.orientation === 'v') || (moveHorizontal === false && this.ui.element.orientation === 'h')) {
                return;
            }

            if (evt.cancelable) {
                evt.preventDefault();
            }

            this.trackController.updateDrag(movePosition);
        }, {passive: false});
    }

    private addEndListener(): void {
        (<EventTarget>document).addEventListener(eventTouchEnd, (): void => {
            this.dragStartPos = null;
            this.trackController.endDrag();
        });
    }
}
