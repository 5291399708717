import type Carousel from '@Component/Carousel/Carousel';
import type SliderControls from '@Component/Carousel/Component/SliderControls';
import type FixedSlider from '@Component/Slider/FixedSlider';

export default class CarouselUI {
    public slider: FixedSlider;
    public controls?: SliderControls;

    constructor(public readonly element: Carousel) {
        this.slider = element.queryRole<FixedSlider>('slider');
        void element.queryRolePromise<SliderControls>('slider-controls').then((sliderControls) => {
            this.controls = sliderControls;
            element.attachHandlers();
        });
    }
}
