import component from '@123/druid/dist/Framework/Decorators/Component';
import onClick from '@123/druid/dist/Framework/Decorators/OnClick';
import prop from '@123/druid/dist/Framework/Decorators/Prop';
import View from '@123/druid/dist/Framework/View/View';
import SearchBarClosedMessage from '@Application/Header/Message/SearchBarClosedMessage';

@component
export default class SearchBarView extends View {
    @prop private keepOpen: boolean = false;

    public show(): this {
        this.el.classList.remove('hide-search');
        this.el.queryRole('search-input').focus();
        return this;
    }

    @onClick('search-panel-closer')
    public hide(): this {
        if (this.keepOpen) {
            return this;
        }
        this.el.classList.add('hide-search');
        this.el.queryRole('search-input').blur();
        void this.emit(new SearchBarClosedMessage());
        return this;
    }

    protected mounted(): void {
        if (this.keepOpen === false) {
            this.el.classList.add('hide-search');
        }
        this.el.classList.remove('hide-on-start');
    }
}
