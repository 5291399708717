import tag from '@123/druid/dist/Framework/Decorators/Tag';
import AttributeHandler from '@Module/Group/Component/QuickFilter/WebComponent/AttributeHandler';
import ChangeHandler from '@Module/Group/Component/QuickFilter/WebComponent/EventHandler/ChangeHandler';
import FilterHandler from '@Module/Group/Component/QuickFilter/WebComponent/EventHandler/FilterHandler';
import FocusHandler from '@Module/Group/Component/QuickFilter/WebComponent/EventHandler/FocusHandler';
import ResetHandler from '@Module/Group/Component/QuickFilter/WebComponent/EventHandler/ResetHandler';
import FilterModel from '@Module/Group/Component/QuickFilter/WebComponent/Model/FilterModel';
import type QuickFilterContext from '@Module/Group/Component/QuickFilter/WebComponent/QuickFilterContext';
import QuickFilterUI from '@Module/Group/Component/QuickFilter/WebComponent/QuickFilterUI';

@tag('dr-quickfilter-input')
export default class QuickFilterInput extends HTMLElement {
    private context?: QuickFilterContext;

    get value(): string {
        return this.context?.filter.getFilter() ?? '';
    }

    set value(value: string) {
        this.context?.filter.setFilterText(value);
    }

    /** element is added to the DOM */
    public connectedCallback(): void {
        this.createContext();
        if (this.context === undefined) {
            return;
        }

        this.context.resetHandler.attach();
        this.context.focusHandler.attach();
        this.context.changeHandler.attach();
        this.context.filterHandler.attach();
    }

    /** element is removed from the DOM */
    public disconnectedCallback(): void {
        if (this.context === undefined) {
            return;
        }

        this.context.resetHandler.detach();
        this.context.focusHandler.detach();
        this.context.changeHandler.detach();
        this.context.filterHandler.detach();
    }

    static get observedAttributes(): string[] {
        return AttributeHandler.observedAttributes;
    }

    public attributeChangedCallback(name: string, _oldValue: string, newValue: string | null): void {
        if (this.context === undefined) {
            return;
        }

        new AttributeHandler(this.context.ui).handleAttribute(name, newValue);
    }

    private createContext(): void {
        const ui            = new QuickFilterUI(this);
        const filter        = new FilterModel();
        const focusHandler  = new FocusHandler(ui);
        const filterHandler = new FilterHandler(ui, filter);
        const changeHandler = new ChangeHandler(ui, filter);
        const resetHandler  = new ResetHandler(ui, filter);

        this.context = {ui, focusHandler, filter, filterHandler, changeHandler, resetHandler};

        // update internal state based on attributes
        QuickFilterInput.observedAttributes.forEach((attr) => {
            if (this.hasAttribute(attr)) {
                this.attributeChangedCallback(attr, '', this.getAttribute(attr));
            }
        });
    }
}
