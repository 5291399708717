import type QuickFilterUI from '@Module/Group/Component/QuickFilter/WebComponent/QuickFilterUI';

export default class FocusHandler {
    private readonly events = 'focusin.quickfilter focusout.quickfilter';
    private isAttached     = false;

    constructor(
        private readonly ui: QuickFilterUI
    ) {
    }

    public attach(): FocusHandler {
        if (this.isAttached) {
            throw new Error('FocusHandler was already attached.');
        }

        this.ui.inputElement.addEventListener(this.events, (evt) => {
            evt.stopPropagation();
            evt.stopImmediatePropagation();
            this.ui.element.dispatchEvent(new Event(evt.type, {bubbles: true, cancelable: true}));
        });

        this.isAttached = true;
        return this;
    }

    public detach(): void {
        if (this.isAttached === false) {
            throw new Error('FocusHandler was not attached.');
        }

        this.ui.element.removeEventListeners(this.events);
        this.isAttached = false;
    }
}
