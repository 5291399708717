import EventHandler from '@123/druid/dist/Framework/EventHandler';
import PropertyChangeModel from '@123/druid/dist/Framework/PropertyChangeModel';

export default class FilterModel extends PropertyChangeModel<string> {
    protected readonly events: EventHandler = new EventHandler();
    private filter: string                  = '';

    public getFilter(): string {
        return this.filter;
    }

    public setFilterText(filterText: string): void {
        filterText = filterText.trim();

        const oldValue = this.filter;
        this.filter    = filterText;
        this.propertyChanged('filter', oldValue, filterText);
    }
}
