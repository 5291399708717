import type {GotoSlideEvent} from '@Component/Carousel/Component/Type/SliderEvent';
import type CarouselUI from '@Component/Carousel/Type/CarouselUI';

export default class ControlHandler {
    private isAttached      = false;

    constructor(private readonly ui: CarouselUI) {
    }

    public attach(): void {
        if (this.isAttached) {
            throw new Error('ControlHandler was already attached.');
        }

        this.ui.controls?.addEventListener('previous', () => this.ui.slider.prevPage());
        this.ui.controls?.addEventListener('next', () => this.ui.slider.nextPage());
        this.ui.controls?.addEventListener('goto', <EventListener>((e: GotoSlideEvent) => this.ui.slider.gotoSlide(e.detail.page)));

        // Create paginator on attach
        this.createPaginator();
        this.setPage();

        this.isAttached = true;
    }

    public createPaginator(): void {
        this.ui.controls?.createPaginator(this.ui.slider.getPageInfo());
    }

    public setPage(): void {
        this.ui.controls?.setPage(this.ui.slider.getPageInfo());
    }

    public toggleControls(hide: boolean): void {
        if (hide) {
            if (this.ui.slider.activePage !== 0) {
                this.ui.slider.activePage = 0;
            }
            this.ui.controls?.classList.add('u-hidden');
        } else {
            this.ui.controls?.classList.remove('u-hidden');
        }
    }

    public detach(): void {
        if (this.isAttached === false) {
            throw new Error('SliderHandler was not attached.');
        }

        this.ui.slider.removeEventListeners('previous next goto');

        this.isAttached = false;
    }
}
