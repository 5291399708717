import tag from '@123/druid/dist/Framework/Decorators/Tag';
import ControlHandler from '@Component/Carousel/EventHandler/ControlHandler';
import SliderHandler from '@Component/Carousel/EventHandler/SliderHandler';
import type CarouselContext from '@Component/Carousel/Type/CarouselContext';
import CarouselUI from '@Component/Carousel/Type/CarouselUI';

@tag('dr-carousel')
export default class Carousel extends HTMLElement {
    private context?: CarouselContext;

    public connectedCallback(): void {
        this.createContext();
    }

    public disconnectedCallback(): void {
        this.getContext().sliderHandler.detach();
        this.getContext().controlHandler.detach();
    }

    // Attach handlers when slider controls is found
    public attachHandlers(): void {
        this.getContext().sliderHandler.attach();
        this.getContext().controlHandler.attach();
    }

    public toggleControls(hide: boolean): void {
        this.getContext().controlHandler.toggleControls(hide);
    }

    private createContext(): void {
        const ui             = new CarouselUI(this);
        const controlHandler = new ControlHandler(ui);
        const sliderHandler  = new SliderHandler(ui);

        this.context = {ui, sliderHandler, controlHandler};
    }

    private getContext(): CarouselContext {
        if (this.context === undefined) {
            throw new Error('No carousel context found.');
        }

        return this.context;
    }

    static get observedAttributes(): string[] {
        return [];
    }
}
