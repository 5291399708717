import {injectable} from '@123/druid/dist/Framework/Decorators/Inject';
import Rectangle from '@123/druid/dist/Utility/Rectangle';

@injectable()
export default class ApplicationConfig {
    private header?: HTMLElement;

    public setHeader(headerElement: HTMLElement): void {
        this.header = headerElement;
    }

    public getScrollBounds(): Rectangle {
        return new Rectangle(0, this.header?.getHeight('outer') ?? 0, 0, 0);
    }
}
