import type CarouselUI from '@Component/Carousel/Type/CarouselUI';

export default class SliderHandler {
    private isAttached      = false;

    constructor(private readonly ui: CarouselUI) {
    }

    public attach(): void {
        if (this.isAttached) {
            throw new Error('SliderHandler was already attached.');
        }

        this.ui.slider.addEventListener('update', () => {
            const hideControls = this.ui.slider.slideCount <= this.ui.slider.slidesVisible;
            this.ui.controls?.createPaginator(this.ui.slider.getPageInfo());
            this.ui.controls?.setPage(this.ui.slider.getPageInfo());
            this.ui.element.toggleControls(hideControls);
        });
        this.ui.slider.dispatchEvent(new CustomEvent('mounted'));

        this.isAttached = true;
    }

    public detach(): void {
        if (this.isAttached === false) {
            throw new Error('SliderHandler was not attached.');
        }

        this.ui.slider.removeEventListeners('update');

        this.isAttached = false;
    }
}
