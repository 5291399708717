import tag from '@123/druid/dist/Framework/Decorators/Tag';
import ClickHandler from '@Component/Carousel/Component/EventHandler/ClickHandler';
import type SliderControlsContext from '@Component/Carousel/Component/Type/SliderControlsContext';
import SliderControlsLayout from '@Component/Carousel/Component/Type/SliderControlsLayout';
import SliderControlsUI from '@Component/Carousel/Component/Type/SliderControlsUI';
import type {SliderPageInfo} from '@Component/Slider/Types/SliderInfo';

@tag('dr-slider-controls')
export default class SliderControls extends HTMLElement {
    private context?: SliderControlsContext;

    public connectedCallback(): void {
        this.createContext();
        this.getContext().clickHandler.attach();
    }

    public disconnectedCallback(): void {
        this.getContext().clickHandler.detach();
    }

    public createPaginator(pageInfo: SliderPageInfo): void {
        this.getContext().layout.createPaginator(pageInfo);
    }

    public setPage(pageInfo: SliderPageInfo): void {
        this.getContext().layout.setPage(pageInfo);
    }

    private createContext(): void {
        const ui           = new SliderControlsUI(this);
        const layout       = new SliderControlsLayout(ui);
        const clickHandler = new ClickHandler(ui);

        this.context = {ui, layout, clickHandler};
    }

    private getContext(): SliderControlsContext {
        if (this.context === undefined) {
            throw new Error('No slider context found.');
        }

        return this.context;
    }

    static get observedAttributes(): string[] {
        return [];
    }
}
