import RectangleFactory from '@123/druid/dist/DOM/RectangleFactory';
import component from '@123/druid/dist/Framework/Decorators/Component';
import onWindowResize from '@123/druid/dist/Framework/Decorators/OnWindowResize';
import View from '@123/druid/dist/Framework/View/View';

@component
export default class DropdownMenuView extends View {
    constructor(private readonly alignElement: HTMLElement) {
        super();
    }

    protected mounted(): void {
        this.updatePosition();
    }

    @onWindowResize()
    private updatePosition(): void {
        this.el.style.top = `${RectangleFactory.elementRect(this.alignElement).bottom - window.scrollY}px`;
    }
}
