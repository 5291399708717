import component from '@123/druid/dist/Framework/Decorators/Component';
import onClick from '@123/druid/dist/Framework/Decorators/OnClick';
import View from '@123/druid/dist/Framework/View/View';
import SearchBarClosedMessage from '@Application/Header/Message/SearchBarClosedMessage';
import DropdownMenuView from '@Application/Header/View/DropdownMenuView';
import SearchBarView from '@Application/Header/View/SearchBarView';

@component
export default class HeaderView extends View {
    private searchBar?: SearchBarView;

    constructor() {
        super();
        this.observe(SearchBarClosedMessage, () => this.el.classList.remove('search-opened'));
    }

    protected mounted(): void {
        this.searchBar = this.getView(SearchBarView);
        this.getView(DropdownMenuView, this.el.queryRole('button-bar'));
    }

    @onClick('dropdownmenu-close')
    protected onCloseDropdownMenu(): void {
        this.el.classList.remove('dropdownmenu-opened');
    }

    @onClick('search-and-menu-opener')
    protected onOpenSearchAndDropdown(): void {
        this.el.classList.add('search-opened', 'dropdownmenu-opened');
    }

    @onClick('open-search-field')
    @onClick('search-opener')
    protected onSearchDesktopHandler(): void {
        this.showSearch();
    }

    @onClick('dropdownmenu-button')
    protected toggleDropdownMenu(): void {
        this.el.classList.toggle('dropdownmenu-opened');
    }

    private showSearch(): void {
        this.el.classList.remove('dropdownmenu-opened');
        this.el.classList.add('search-opened');
        this.searchBar?.show();
    }
}
